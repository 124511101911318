import React from "react";
import WhitePaperPT from "../../assets/pdf/whitePaper.pdf";

function WhitePaper() {
  return (
    <iframe
      src={WhitePaperPT}
      style={{ width: "100%", height: "100vh" }}
    ></iframe>
  );
}

export default WhitePaper;
