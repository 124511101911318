import React, { useEffect, useState } from "react";
import { ReactComponent as Linha1 } from "../../assets/img/Linha1.svg";
import { ReactComponent as Linha2 } from "../../assets/img/Linha2.svg";
import { ReactComponent as Linha3 } from "../../assets/img/Linha3.svg";
import { ReactComponent as Linha4 } from "../../assets/img/Linha4.svg";
import { ReactComponent as Linha5 } from "../../assets/img/Linha5.svg";
import { keyframes } from "styled-components";
import Elipse1 from "../../assets/img/Elipse1.png";
import { useStore } from "../../context/storeContext";
import Counter from "./counter";

function Graph() {
  const { page, lang, language } = useStore();
  const text = language[lang].distributionGraph;
  const dash = keyframes`
  from{
    stroke-dasharray: 1000;
    stroke-dashoffset: 4000;
    opacity:0.4;
  }
  to{
    stroke-dasharray: 2000;
    stroke-dashoffset: 100;
    opacity:1;
  }
  `;
  const fade = keyframes`
  from{
    opacity:0;
  }
  to{
    opacity:1;
  }
  `;

  return (
    <div
      style={{
        width: "100vw",
        height: "100vh",
        transition: page === 6 ? "opacity ease 3s" : "opacity ease .5s",
        opacity: page === 6 ? 1 : 0,
      }}
    >
      <div
        className="page-graph-responsive"
        style={{
          width: "100%",
          height: "100%",
          position: "absolute",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <div
          className="page-graph-responsive-container"
          style={{
            position: "relative",
            marginTop: "-9rem",
            marginLeft: "3.5rem",
            width: "450px",
            height: "780px",
          }}
        >
          <Linha1
            style={{
              transform: "scale(0.6)",
              position: "absolute",
              bottom: "-10rem",
              left: "-2rem",
              animation:
                page === 6 ? `${dash} 10s infinite alternate linear` : null,
              animationDelay: "1.5s",
            }}
          />
          <img
            src={Elipse1}
            alt="elipse"
            style={{
              transform: "scale(0.7)",
              position: "absolute",
              top: "1.7rem",
              left: "12.3rem",
            }}
          />
          <Linha4
            style={{
              position: "absolute",
              top: "10.7rem",
              left: "12.3rem",
              transform: "scale(0.7)",
              animation: page === 6 && `${dash} 10s infinite alternate linear`,
              animationDelay: "3s",
            }}
          />
          <img
            src={Elipse1}
            alt="elipse"
            style={{
              transform: "scale(0.5)",
              position: "absolute",
              top: "9.93rem",
              left: "22rem",
            }}
          />
          <img
            src={Elipse1}
            alt="elipse"
            style={{
              transform: "scale(0.35)",
              position: "absolute",
              top: "10.6rem",
              left: "21rem",
            }}
          />
          <Linha4
            style={{
              position: "absolute",
              top: "11.4rem",
              left: "11.3rem",
              transform: "scale(0.7)",
              animation: page === 6 && `${dash} 10s infinite alternate linear`,
              animationDelay: "0.5s",
            }}
          />
          <img
            src={Elipse1}
            alt="elipse"
            style={{
              transform: "scale(0.35)",
              position: "absolute",
              top: "15rem",
              left: "2rem",
              animation: `${fade} alternate infinite 1.1s`,
              animationDelay: "1.8s",
            }}
          />
          <img
            src={Elipse1}
            alt="elipse"
            style={{
              transform: "scale(0.35)",
              position: "absolute",
              top: "16rem",
              left: "2rem",
              animation: `${fade} alternate infinite 1.5s`,
              animationDelay: "3s",
            }}
          />
          <img
            src={Elipse1}
            alt="elipse"
            style={{
              transform: "scale(0.35)",
              position: "absolute",
              top: "17rem",
              left: "2rem",
              animation: `${fade} alternate infinite 1.9s`,
              animationDelay: ".2s",
            }}
          />
          <Linha2
            style={{
              transform: "scale(0.6)",
              position: "absolute",
              bottom: "1.35rem",
              left: "11rem",
              animation: page === 6 && `${dash} 10s infinite linear`,
              animationDelay: "0.1s",
            }}
          />
          <Linha3
            style={{
              transform: "scale(0.6)",
              position: "absolute",
              top: "-0.2rem",
              left: "-2.9rem",
              animation: page === 6 && `${dash} 10s infinite alternate linear`,
              animationDelay: "0.5s",
            }}
          />
          {page === 6 && <Counter text={text} />}
          <img
            src={Elipse1}
            alt="elipse"
            style={{
              transform: "scale(0.57)",
              position: "absolute",
              top: "3.5rem",
              left: "11.1rem",
            }}
          />
          <img
            src={Elipse1}
            alt="elipse"
            style={{
              transform: "scale(0.50)",
              position: "absolute",
              top: "29rem",
              left: "1rem",
            }}
          />
          <div
            style={{
              width: "8rem",
              height: "8rem",
              background: "grey",
              borderRadius: "50%",
              position: "absolute",
              top: "19rem",
              left: "-2.5rem",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <p style={{ fontFamily: "gilroy-thin" }}>19.5%</p>
            <h4>351.000.000</h4>
          </div>
          <Linha5
            style={{
              transform: "scale(0.6)",
              position: "absolute",
              left: "-10rem",
              top: "21.5rem",
              animation: page === 6 && `${dash} 10s infinite linear`,
              animationDelay: "0.5s",
            }}
          />
          <img
            src={Elipse1}
            alt="elipse"
            style={{
              transform: "scale(0.35)",
              position: "absolute",
              top: "21.1rem",
              left: "-9rem",
            }}
          />
          <div
            style={{
              position: "absolute",
              color: "black",
              top: "20rem",
              left: "-15rem",
              textAlign: "center",
            }}
          >
            <h1>IFO</h1>
            <p>{text[0]}</p>
          </div>
          <div
            style={{
              width: "8rem",
              height: "8rem",
              background: "grey",
              borderRadius: "50%",
              position: "absolute",
              top: "33rem",
              left: "-2.5rem",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <p style={{ fontFamily: "gilroy-thin" }}>20.5%</p>
            <h4>369.000.000</h4>
          </div>
          <Linha5
            style={{
              transform: "scale(0.6)",
              position: "absolute",
              left: "-10rem",
              top: "35.5rem",
              animation: page === 6 && `${dash} 10s infinite linear`,
              animationDelay: "0.2s",
            }}
          />
          <img
            src={Elipse1}
            alt="elipse"
            style={{
              transform: "scale(0.35)",
              position: "absolute",
              top: "35.05rem",
              left: "-9rem",
            }}
          />
          <div
            style={{
              position: "absolute",
              color: "black",
              top: "35rem",
              left: "-18rem",
              textAlign: "center",
            }}
          >
            <h1>IEO</h1>
            <p>{text[1]}</p>
            <p>{text[2]}</p>
          </div>
          <div
            style={{
              width: "8rem",
              height: "8rem",
              background: "grey",
              borderRadius: "50%",
              position: "absolute",
              top: "40.5rem",
              left: "9rem",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <p>19%</p>
            <h4>342.000.000</h4>
          </div>
          <Linha5
            style={{
              transform: "scale(0.5) rotate(-90deg)",
              position: "absolute",
              left: "7.7rem",
              top: "49.8rem",
              animation: page === 6 && `${dash} 10s infinite linear`,
              animationDelay: "1s",
            }}
          />
          <div
            style={{
              position: "absolute",
              color: "black",
              top: "54rem",
              left: "4.5rem",
              textAlign: "center",
            }}
          >
            <h1>IEO</h1>
            <p>{text[3]}</p>
          </div>
          <img
            src={Elipse1}
            alt="elipse"
            style={{
              transform: "scale(0.35)",
              position: "absolute",
              top: "52.3rem",
              left: "11rem",
            }}
          />
          <div
            style={{
              width: "8rem",
              height: "8rem",
              background: "red",
              borderRadius: "50%",
              position: "absolute",
              top: "33rem",
              left: "18.6rem",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <p style={{ fontFamily: "gilroy-thin" }}>20%</p>
            <h4>450.000</h4>
          </div>
          <Linha5
            style={{
              transform: "scale(0.5) rotate(180deg)",
              position: "absolute",
              right: "-5.4rem",
              top: "36.5rem",
              animation: page === 6 && `${dash} 15s infinite linear`,
              animationDelay: "0.5s",
            }}
          />
          <div
            style={{
              position: "absolute",
              color: "black",
              top: "35.5rem",
              right: "-14.8rem",
              textAlign: "center",
            }}
          >
            <h1>LOCKED</h1>
            <p style={{ fontFamily: "gilroy-thin", fontWeight: "600" }}>
              {text[4]}
            </p>
            <p style={{ fontFamily: "gilroy-thin", fontWeight: "600" }}>
              {text[5]}
            </p>
            <h1 style={{ fontFamily: "gilroy-thin", fontSize: "1.4rem" }}>
              {text[6]}
            </h1>
          </div>
          <img
            src={Elipse1}
            alt="elipse"
            style={{
              transform: "scale(0.35)",
              position: "absolute",
              top: "37.05rem",
              right: "-4rem",
            }}
          />
          <div
            style={{
              width: "8rem",
              height: "8rem",
              background: "blue",
              borderRadius: "50%",
              position: "absolute",
              top: "7.3rem",
              left: "9rem",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <div
              style={{
                width: "8rem",
                height: "8rem",
                background:
                  "linear-gradient(149.93deg, #03C2CE 6.18%, #1C23CB 77.27%)",
                filter: "blur(10px)",
                borderRadius: "50%",
                position: "absolute",
              }}
            />
            <p style={{ fontFamily: "gilroy-thin" }}>20.5%</p>
            <h4>288.000.000</h4>
          </div>
          <img
            src={Elipse1}
            alt="elipse"
            style={{
              transform: "scale(0.35)",
              position: "absolute",
              top: "42.8rem",
              left: "6rem",
              animation: `${fade} alternate infinite 1.1s`,
              animationDelay: ".7s",
            }}
          />
          <img
            src={Elipse1}
            alt="elipse"
            style={{
              transform: "scale(0.35)",
              position: "absolute",
              top: "43.8rem",
              left: "6rem",
              animation: `${fade} alternate infinite 1.6s`,
              animationDelay: ".7s",
            }}
          />
          <img
            src={Elipse1}
            alt="elipse"
            style={{
              transform: "scale(0.35)",
              position: "absolute",
              top: "44.8rem",
              left: "6rem",
              animation: `${fade} alternate infinite 1.9s`,
              animationDelay: ".2s",
            }}
          />
        </div>
      </div>
    </div>
  );
}

export default Graph;
