import React, { useEffect, useState } from "react";
import { useStore } from "../../context/storeContext";
import EqualsSports from "../../assets/img/fotoEqualsSports.png";
import HiveAntes from "../../assets/img/fotoHiveAntes.png";
import EqualsVenue from "../../assets/img/fotoEqualsVenue.gif";
import HiveDepois from "../../assets/img/fotoHiveDepois.png";
import ReinoBurger from "../../assets/img/FotoReinoBurger.webp";
import HiveJogos from "../../assets/img/fotoReinoBurger.png";

import Particles from "../../components/particles";
import Card from "./card";

function PicturesPage() {
  const { page } = useStore();
  const [scale, setScale] = useState(50);
  const [rotate, setRotate] = useState(190);
  useEffect(() => {
    if (page === 8) {
      setScale(1);
      setTimeout(() => {
        setRotate(0);
      }, 500);
    } else {
      setScale(1.2);
      setTimeout(() => {
        setRotate(25);
      }, 300);
    }
  }, [page]);
  return (
    <section
      style={{
        background: "linear-gradient(138.62deg, #022355 0.89%, #010D1E 95.72%)",
        transition:
          page === 8
            ? "transform ease 2s, opacity ease 2s"
            : "transform ease .4s, opacity ease 1s",
        transform: `scale(${page === 8 ? 1.01 : 2.4})`,
        opacity: page === 8 ? 1 : 0,
        width: "100vw",
        height: "100vh",
        zIndex: 1,
      }}
    >
      {page === 8 && (
        <div
          style={{
            position: "absolute",
            width: "100vw",
            height: "100vh",
            zIndex: 1,
          }}
        >
          <Particles />
        </div>
      )}
      <div
        style={{
          position: "absolute",
          top: "4%",
          right: "7%",
          fontFamily: "gilroy-bold",
          color: "#eee",
          fontSize: "1.6rem",
        }}
      >
        EMPRESAS DO GRUPO EQUALS9
      </div>
      <div
        style={{
          position: "relative",
          width: "100%",
          height: "100%",
          transition:
            page === 8
              ? "transform ease 2s,opacity ease 4s,filter ease 3s"
              : "transform 0.5s, opacity 0.5s,filter 0.5s",
          transform: `scale(${scale}) rotate(${rotate}deg)`,
          filter: page === 8 ? null : "blur(15px)",
          opacity: page === 8 ? 1 : 0,
          marginTop: "-8rem",
          zIndex: 1,
        }}
      >
        <Card
          size={{ w: "6.61px", h: "6.61px" }}
          position={{ t: "49.5%", r: null, b: null, l: "49.5%" }}
        ></Card>
        <Card
          size={{ w: "12px", h: "12px" }}
          position={{ t: "48%", r: null, b: null, l: "48%" }}
        ></Card>
        <Card
          size={{ w: "20px", h: "20px" }}
          position={{ t: "50%", r: null, b: null, l: "46%" }}
        ></Card>
        <Card
          size={{ w: "35px", h: "35px" }}
          position={{ t: "55%", r: null, b: null, l: "46.5%" }}
        ></Card>
        <Card
          size={{ w: "60px", h: "60px" }}
          position={{ t: "56%", r: null, b: null, l: "50%" }}
        ></Card>
        <Card
          size={{ w: "80px", h: "80px" }}
          position={{ t: "48%", r: null, b: null, l: "55%" }}
        ></Card>
        <Card
          size={{ w: "100px", h: "100px" }}
          position={{ t: "31%", r: null, b: null, l: "53%" }}
        ></Card>
        <Card
          size={{ w: "140px", h: "140px" }}
          position={{ t: "19%", r: null, b: null, l: "42.5%" }}
        ></Card>
        <Card
          size={{ w: "180px", h: "180px" }}
          position={{ t: "25%", r: null, b: null, l: "28.5%" }}
          image={EqualsVenue}
          imageStyle={{
            width: "100%",
            height: "180px",
            padding: "6px",
            borderRadius: "50%",
            objectFit: "cover",
            objectPosition: "right",
          }}
          popupTxt={"HIVE Antes / Depois"}
        >
          <img
            src={HiveDepois}
            alt="hivedepois"
            style={{
              width: "178px",
              height: "178px",
              padding: "6px",
              borderRadius: "50%",
              objectFit: "cover",
              objectPosition: "right",
            }}
          />
        </Card>
        <Card
          size={{ w: "220px", h: "220px" }}
          position={{ t: "50%", r: null, b: null, l: "22%" }}
          image={ReinoBurger}
          imageStyle={{
            width: "100%",
            height: "220px",
            padding: "6px",
            borderRadius: "50%",
            objectFit: "cover",
          }}
          popupTxt={"Reino Burger"}
        >
          <div style={{ transform: "scale(.9)" }}>
            <h3 style={{ fontSize: "1.1rem", paddingBottom: "1rem" }}>
              REINO BURGUER LTDA
            </h3>
            <p style={{ fontFamily: "gilroy-thin", fontSize: "1.1rem" }}>
              CNPJ: 43.783.063/0001-74
            </p>
            <h3 style={{ fontSize: "1.1rem", padding: "10px" }}>
              PARTICIPAÇÃO: 30%
            </h3>
          </div>
        </Card>
        <Card
          size={{ w: "240px", h: "240px" }}
          position={{ t: "79%", r: null, b: null, l: "30%" }}
          image={HiveJogos}
          imageStyle={{
            width: "240px",
            height: "240px",
            padding: "6px",
            borderRadius: "50%",
            objectFit: "cover",
            objectPosition: "right",
          }}
          popupTxt={"Hive Jogos"}
        >
          <div style={{ transform: "scale(1.1)" }}>
            <h3 style={{ fontSize: "0.7rem" }}>HIVE JOGOS </h3>
            <h3 style={{ fontSize: "0.7rem", paddingBottom: "1rem" }}>
              COMÉRCIO E LUDERIA LTDA
            </h3>
            <p style={{ fontFamily: "gilroy-thin", fontSize: "0.9rem" }}>
              CNPJ: 31.590.039/0001-30
            </p>
            <h3 style={{ fontSize: "0.9rem", padding: "10px" }}>
              PARTICIPAÇÃO: 65%
            </h3>
          </div>
        </Card>
        <Card
          size={{ w: "263px", h: "263px" }}
          position={{ t: "82%", r: null, b: null, l: "50%" }}
          image={HiveAntes}
          imageStyle={{
            width: "263px",
            height: "263px",
            padding: "6px",
            borderRadius: "50%",
            objectFit: "cover",
            // objectPosition: "left",
          }}
          popupTxt={"Equals Venue"}
        >
          <div style={{ transform: "scale(1.25)" }}>
            <h3 style={{ fontSize: "0.7rem" }}>EQUALVENUE </h3>
            <h3 style={{ fontSize: "0.7rem" }}>REVOLUÇÃO EM PAGAMENTOS</h3>
            <h3 style={{ fontSize: "0.7rem", paddingBottom: "10px" }}>
              DIGITAIS LTDA
            </h3>
            <p style={{ fontFamily: "gilroy-thin", fontSize: "0.78rem" }}>
              CNPJ: 42.731.255/0001-74
            </p>
            <h3 style={{ fontSize: "0.9rem", padding: "10px" }}>
              PARTICIPAÇÃO: 95%
            </h3>
          </div>
        </Card>
        <Card
          style={{
            position: "absolute",
            width: "280px",
            height: "280px",
            left: "67%",
            top: "50%",
            borderRadius: "50%",
            background: "linear-gradient(180deg, #9A04B3 0%, #5200FF 100%)",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
          size={{ w: "280px", h: "280px" }}
          position={{ t: "50%", r: null, b: null, l: "67%" }}
          image={EqualsSports}
          imageStyle={{
            width: "280px",
            height: "280px",
            padding: "6px",
            borderRadius: "50%",
            objectFit: "cover",
            // objectPosition: "right",
          }}
          popupTxt="EqualsSport"
        >
          <div style={{ transform: "scale(1.4)" }}>
            <h3 style={{ fontSize: "0.7rem" }}>EQUALSSPORT GESTÃO</h3>
            <h3 style={{ fontSize: "0.7rem" }}>DE EVENTOS E ESPORTES</h3>
            <h3 style={{ fontSize: "0.7rem", paddingBottom: "10px" }}>
              ELETRÔNICOS S/A
            </h3>
            <p style={{ fontFamily: "gilroy-thin", fontSize: "0.9rem" }}>
              CNPJ: 42.017.231/0001-58
            </p>
            <h3 style={{ fontSize: "0.9rem", padding: "10px" }}>
              PARTICIPAÇÃO: 90%
            </h3>
          </div>
        </Card>
      </div>
    </section>
  );
}

export default PicturesPage;
