import React, { useEffect, useState } from "react";
import { useStore } from "../../context/storeContext";
import LogoEquals9 from "../../assets/img/LogoEquals9.png";
import AccountTreeIcon from "@mui/icons-material/AccountTree";
import TranslateIcon from "@mui/icons-material/Translate";
import TimelineIcon from "@mui/icons-material/Timeline";
import GroupIcon from "@mui/icons-material/Group";
import Divider from "@mui/material/Divider";
import api from "../../service/api";
import Projects from "./projects";
import Timeline from "./timeline";
import Language from "./textos";
import Team from "./team";
import "./admin.scss";

function Index() {
  const { setTimeline, setNews, setLanguage } = useStore();
  const [menu, setMenu] = useState("team");

  const handleMenu = (name) => {
    setMenu(name);
  };

  useEffect(() => {
    const fetchData = async () => {
      const lang = await api.get("lang").catch(console.log);
      const getProjects = await api.get("projects").catch(console.log);
      const getTimeline = await api.get("timeline").catch(console.log);
      setLanguage(lang.data.docs);
      setTimeline(getTimeline.data.docs);
      setNews(getProjects.data.docs);
    };
    return fetchData();
  }, []);

  return (
    <div className="adminPage">
      <div className="admMenu">
        <section className="menuLogo">
          <img src={LogoEquals9} alt="logoEquals9" />
          <h1>ADMINISTRAÇÃO</h1>
        </section>
        <Divider />
        <section className="menuItems">
          <div
            className={menu === "team" ? "active" : null}
            onClick={() => handleMenu("team")}
          >
            <GroupIcon className="iconMenu" />
            <p>Equipe</p>
          </div>
          <div
            type="button"
            className={menu === "projects" ? "active" : null}
            onClick={() => handleMenu("projects")}
          >
            <AccountTreeIcon className="iconMenu" />
            <p>Projetos</p>
          </div>
          <div
            className={menu === "timeline" ? "active" : null}
            onClick={() => handleMenu("timeline")}
          >
            <TimelineIcon className="iconMenu" />
            <p>Linha do Tempo</p>
          </div>
          <div
            className={menu === "language" ? "active" : null}
            onClick={() => handleMenu("language")}
          >
            <TranslateIcon className="iconMenu" />
            <p>Linguagem</p>
          </div>
        </section>
        <Divider />
      </div>
      <div className="pageContent">
        {menu === "timeline" ? (
          <Timeline />
        ) : menu === "projects" ? (
          <Projects />
        ) : menu === "team" ? (
          <Team />
        ) : menu === "language" ? (
          <Language />
        ) : null}
      </div>
    </div>
  );
}

export default Index;
