import React from "react";
import WhitePaperEN from "../../assets/pdf/whitePaper.pdf";

function WhitePaper() {
  return (
    <iframe
      src={WhitePaperEN}
      style={{ width: "100%", height: "100vh" }}
    ></iframe>
  );
}

export default WhitePaper;
