import React, { useEffect, useState, useRef } from "react";
import Home from "../../pages/index";
import Mission from "../../pages/mission";
import Participation from "../../pages/participate";
import Project from "../../pages/project";
import Timeline from "../../pages/timeline";
import Team from "../../pages/team";
import { useStore } from "../../context/storeContext";
import "./scrollSmooth.scss";
import $ from "jquery";
import Distribution from "../../pages/distribution";
import Marker from "./marker";
import api from "../../service/api";
import Graph from "../../pages/distribution/graph";
import PicturesPage from "../../pages/picturesPage";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import ReactTouchEvents from "react-touch-events";
import Particlesjs from "../particles";
function App() {
  // const location = useLocation();
  const {
    setTimeline,
    setNews,
    page,
    setPage,
    lang,
    setLang,
    setTeam,
    setLanguage,
    language,
  } = useStore();
  const [currentSlideNumber, setCurrentSlideNumber] = useState(1);
  const [slidingUp, setSlidingUp] = useState(false);
  const [slidingDown, setSlidingDown] = useState(false);
  const [isDragging, setDragging] = useState(false);
  const [startTouchPosition, setStartTouchPosition] = useState();
  const [lastPositionTouch, setLastPositionTouch] = useState();
  const [margin, setMargin] = useState();
  const scrollSense = 0;
  const wheel = useRef();
  const slideDuration = 600;
  const totalSlideNumber = $(".background").length;
  const mouse = window.event;

  useEffect(() => {
    const fetchData = async () => {
      const getProjects = await api.get("projects").catch(console.log);
      const getTimeline = await api.get("timeline").catch(console.log);
      const getTeamCrew = await api.get("team").catch(console.log);
      const getLang = await api.get("lang").catch(console.log);
      setLanguage(getLang.data.docs[0]);
      setTeam(getTeamCrew.data.docs);
      setTimeline(getTimeline.data.docs);
      setNews(getProjects.data.docs);
    };
    return fetchData();
  }, []);
  useEffect(() => {
    setPage(1);
  }, []);

  function slideDurationTimeout(slideDuration) {
    setTimeout(function () {
      setSlidingDown(false);
      setSlidingUp(false);
    }, slideDuration);
  }

  const changeClass = (number) => {
    return currentSlideNumber === number ? "up-scroll" : "down-scroll";
  };

  const handleWheel = (e) => {
    if (!slidingUp && !slidingDown) {
      if (e.deltaY <= scrollSense) {
        setSlidingUp(true);
        if (currentSlideNumber > 1) {
          setPage(page - 1);
          setCurrentSlideNumber(currentSlideNumber - 1);
        }
        slideDurationTimeout(slideDuration);
      }
      if (e.deltaY >= scrollSense) {
        setSlidingDown(true);
        slideDurationTimeout(slideDuration);
        if (currentSlideNumber < totalSlideNumber) {
          setPage(page + 1);
          setCurrentSlideNumber(currentSlideNumber + 1);
        }
      }
    }
  };

  const handleTouchStart = (e) => {
    setDragging(true);
    const touchY = e.touches[0].clientY;
    setStartTouchPosition(touchY);
  };

  const handleTouchEnd = () => {
    if (lastPositionTouch - 100 > startTouchPosition) {
      setSlidingUp(true);
      if (currentSlideNumber > 1) {
        setPage(page - 1);
        setCurrentSlideNumber(currentSlideNumber - 1);
      }
      slideDurationTimeout(slideDuration);
    } else if (lastPositionTouch + 100 < startTouchPosition) {
      setSlidingDown(true);
      slideDurationTimeout(slideDuration);
      if (currentSlideNumber < totalSlideNumber) {
        setPage(page + 1);
        setCurrentSlideNumber(currentSlideNumber + 1);
      }
    }
    setDragging(false);
    setStartTouchPosition();
    setLastPositionTouch();
    setMargin(0);
  };

  const handleSwipe = (e) => {
    if (isDragging) {
      const touchY = e.touches[0].clientY;
      setLastPositionTouch(touchY);
      setMargin(lastPositionTouch - startTouchPosition);
    }
  };

  if (!language)
    return (
      <Box
        sx={{
          display: "flex",
          position: "absolute",
          alignItems: "center",
          justifyContent: "center",
          width: "100vw",
          height: "100vh",
        }}
      >
        <CircularProgress
          style={{ width: "120px", height: "120px", opacity: "0.3" }}
        />
      </Box>
    );
  return (
    // <ReactTouchEvents onSwipe={handleSwipe}>
    <div
      className="App"
      onTouchStart={handleTouchStart}
      onTouchEnd={handleTouchEnd}
      onTouchMove={handleSwipe}
      style={{
        marginTop: margin,
        transition: "margin .2s ease-out",
      }}
    >
      <section
        className={`background ${changeClass(1)}`}
        style={{
          opacity: page === 1 ? 1 : 0,
          zIndex: page === 1 ? 1000 : 0,
          filter: page !== 1 ? "blur(5px)" : null,
        }}
        ref={wheel}
        onWheel={(e) => handleWheel(e)}
      >
        <div className="content-wrapper">
          <Home />
        </div>
      </section>
      <section
        className={`background ${changeClass(2)}`}
        style={{
          opacity: page === 2 ? 1 : 0,
          zIndex: page === 2 ? 1000 : 0,
          filter: page !== 2 ? "blur(5px)" : null,
        }}
        onWheel={(e) => handleWheel(e)}
      >
        <div className="content-wrapper">
          <Mission />
        </div>
      </section>
      {/* <section
        className={`background  ${changeClass(3)}`}
        onWheel={(e) => handleWheel(e)}
      >
        <div className="content-wrapper">
          <Carousel />
        </div>
      </section> */}
      <section
        className={`background  ${changeClass(3)}`}
        style={{
          opacity: page === 3 ? 1 : 0,
          zIndex: page === 3 ? 1000 : 0,
          filter: page !== 3 ? "blur(5px)" : null,
        }}
        onWheel={(e) => handleWheel(e)}
      >
        <div className="content-wrapper">
          <Timeline />
        </div>
      </section>
      <section
        className={`background  ${changeClass(4)}`}
        style={{
          opacity: page === 4 ? 1 : 0,
          zIndex: page === 4 ? 1000 : 0,
          filter: page !== 4 ? "blur(5px)" : null,
        }}
        onWheel={(e) => handleWheel(e)}
      >
        <div className="content-wrapper">
          <Project />
        </div>
      </section>
      <section
        className={`background  ${changeClass(5)}`}
        style={{
          opacity: page === 5 ? 1 : 0,
          zIndex: page === 5 ? 1000 : 0,
          filter: page !== 5 ? "blur(5px)" : null,
        }}
        onWheel={(e) => handleWheel(e)}
      >
        <div className="content-wrapper">
          <Distribution />
        </div>
      </section>
      <section
        className={`background  ${changeClass(6)}`}
        style={{
          opacity: page === 6 ? 1 : 0,
          zIndex: page === 6 ? 1000 : 0,
          filter: page !== 6 ? "blur(5px)" : null,
        }}
        onWheel={(e) => handleWheel(e)}
      >
        <div className="content-wrapper">
          <Graph />
        </div>
      </section>
      <section
        className={`background ${changeClass(7)}`}
        style={{
          opacity: page === 7 ? 1 : 0,
          zIndex: page === 7 ? 1000 : 0,
          filter: page !== 7 ? "blur(5px)" : null,
        }}
        onWheel={(e) => handleWheel(e)}
      >
        <div className="content-wrapper">
          <Team />
        </div>
      </section>
      <section
        className={`background ${changeClass(8)}`}
        style={{
          opacity: page === 8 ? 1 : 0,
          zIndex: page === 8 ? 1000 : 0,

          filter: page !== 8 ? "blur(5px)" : null,
        }}
        onWheel={(e) => handleWheel(e)}
      >
        <div className="content-wrapper">
          {/* <Particlesjs /> */}

          <PicturesPage />
        </div>
      </section>
      <section
        className={`background ${changeClass(9)}`}
        style={{
          opacity: page === 9 ? 1 : 0,
          zIndex: page === 9 ? 1000 : 0,
          filter: page !== 9 ? "blur(5px)" : null,
        }}
        onWheel={(e) => handleWheel(e)}
      >
        <div className="content-wrapper">
          <Participation />
        </div>
      </section>
      <div style={{}}>
        <Marker />
      </div>
      <div
        style={{
          position: "absolute",
          top: "1rem",
          right: "1rem",
          zIndex: 2000,
        }}
      >
        <select
          defaultValue={lang}
          onChange={(e) => setLang(e.target.value)}
          style={{
            color: page === 1 || page === 8 || page === 9 ? "white" : "#016",
            transition: "color ease 1s",
            fontSize: "1.1rem",
            fontFamily: "gilroy-bold",
            background: "none",
            border: "none",
          }}
        >
          <option value="en">en</option>
          <option value="pt">pt</option>
        </select>
      </div>
    </div>
    // </ReactTouchEvents>
  );
}

export default App;
