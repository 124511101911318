import React, { useEffect, useState } from "react";
import { useStore } from "../../context/storeContext";
// import language from "../../assets/lang";

function Counter() {
  const { page, lang, language } = useStore();
  const [secondsRadius, setSecondsRadius] = useState();
  const text = language[lang].distribution;
  const calculateTimeLeft = () => {
    // let year = new Date().getFullYear();
    let year = 2021;
    const difference = +new Date(`${year}-12-10`) - +new Date();
    let timeLeft = {};

    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60),
      };
    }

    return timeLeft;
  };
  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());
  function mapNumber(number, in_min, in_max, out_min, out_max) {
    return (
      ((number - in_min) * (out_max - out_min)) / (in_max - in_min) + out_min
    );
  }
  const SVGCircle = ({ radius }) => (
    <svg
      className="countdown-svg"
      style={{ position: "absolute", right: "-17.1rem", top: "-10px" }}
    >
      <path
        fill="none"
        stroke="#333"
        stroke-width="4"
        d={describeArc(25, 18, 17, 10, radius)}
      />
    </svg>
  );
  function polarToCartesian(centerX, centerY, radius, angleInDegrees) {
    var angleInRadians = ((angleInDegrees - 90) * Math.PI) / 180.0;

    return {
      x: centerX + radius * Math.cos(angleInRadians),
      y: centerY + radius * Math.sin(angleInRadians),
    };
  }
  function describeArc(x, y, radius, startAngle, endAngle) {
    var start = polarToCartesian(x, y, radius, endAngle);
    var end = polarToCartesian(x, y, radius, startAngle);

    var largeArcFlag = endAngle - startAngle <= 180 ? "0" : "1";

    var d = [
      "M",
      start.x,
      start.y,
      "A",
      radius,
      radius,
      0,
      largeArcFlag,
      0,
      end.x,
      end.y,
    ].join(" ");

    return d;
  }
  useEffect(() => {
    if (page === 6) {
      // setDaysRadius(mapNumber(timeLeft.days, 30, 0, 0, 360));
      // setHoursRadius(mapNumber(timeLeft.hours, 24, 0, 0, 360));
      // setMinutesRadius(mapNumber(timeLeft.minutes, 60, 0, 0, 360));
      setSecondsRadius(mapNumber(timeLeft.seconds, 60, 0, 0, 360));
    }
    console.log(timeLeft);
  }, [timeLeft]);

  // const [year] = useState(new Date().getFullYear());

  setTimeout(() => {
    setTimeLeft(calculateTimeLeft());
  }, 1000);

  return (
    <div>
      <div>
        <h1
          style={{
            color: "black",
            fontFamily: "gilroy-thin",
            fontSize: "1rem",
            position: "absolute",
            top: "10rem",
            right: "-8rem",
            textAlign: "center",
            width: "250px",
            transform: "scale(0.7)",
            // background: "black",
          }}
        >
          <p>{text[7]}</p>
          <p>{text[8]}: 288.000.000</p>
          {timeLeft ? (
            <div
              style={{
                display: "flex",
                width: "100%",
                justifyContent: "center",
                marginLeft: "-1.5rem",
                marginTop: "1.5rem",
                transform: "scale(1.4)",
              }}
            >
              <div
                style={{
                  padding: "0 0.7rem",
                  fontFamily: "digital-counter",
                }}
              >
                <p
                  style={{
                    fontFamily: "digital-counter",
                    marginBottom: "5px",
                  }}
                >
                  {timeLeft.days}
                </p>
                <p style={{ fontSize: ".5em", fontFamily: "gilroy-bold" }}>
                  {text[10]}
                </p>
              </div>

              <p>:</p>
              <div
                style={{
                  fontFamily: "digital-counter",
                  marginBottom: "5px",
                }}
              >
                <p
                  style={{
                    fontFamily: "digital-counter",
                    marginBottom: "5px",
                  }}
                >
                  {timeLeft.hours}
                </p>
                <p style={{ fontSize: ".5em", fontFamily: "gilroy-bold" }}>
                  {text[11]}
                </p>
              </div>
              <p>:</p>

              <div
                style={{
                  padding: "0 0.7rem",
                  fontFamily: "digital-counter",
                }}
              >
                <p
                  style={{
                    fontFamily: "digital-counter",
                    marginBottom: "5px",
                  }}
                >
                  {timeLeft.minutes}
                </p>
                <p style={{ fontSize: ".5em", fontFamily: "gilroy-bold" }}>
                  {text[12]}
                </p>
              </div>
              <div
                style={{
                  padding: "0 0.7rem",
                  fontFamily: "digital-counter",
                  width: "25px",
                  position: "absolute",
                  right: "1.6rem",
                  textAlign: "center",
                }}
              >
                <p
                  style={{
                    fontFamily: "digital-counter",
                    marginBottom: "5px",
                    minWidth: "25px",
                    textAlign: "center",
                  }}
                >
                  {timeLeft.seconds}
                </p>
                <div>
                  <SVGCircle radius={secondsRadius} />
                </div>
              </div>

              <div></div>
            </div>
          ) : (
            <span>Time's up!</span>
          )}
        </h1>
      </div>
    </div>
  );
}

export default Counter;
