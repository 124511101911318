import React, { useRef } from "react";
import Divider from "@mui/material/Divider";
import api from "../../service/api";
import { useStore } from "../../context/storeContext";
import { Button } from "@mui/material";

function Timeline() {
  const { timeline, setTimeline } = useStore();

  const timelineName = useRef();
  const timelineDesc = useRef();
  const timelineMonth = useRef();
  const timelineYear = useRef();

  const SelectItems = [
    { pt: "JAN", en: "JAN" },
    { pt: "FEV", en: "FEB" },
    { pt: "MAR", en: "MAR" },
    { pt: "ABR", en: "APR" },
    { pt: "MAI", en: "MAY" },
    { pt: "JUN", en: "JUN" },
    { pt: "JUL", en: "JUL" },
    { pt: "AGO", en: "AUG" },
    { pt: "SET", en: "SEP" },
    { pt: "OUT", en: "OCT" },
    { pt: "NOV", en: "NOV" },
    { pt: "DEZ", en: "DEC" },
    { pt: "ANO", en: "YR" },
    { pt: "Q1", en: "Q1" },
    { pt: "Q2", en: "Q2" },
    { pt: "Q3", en: "Q3" },
    { pt: "Q4", en: "Q4" },
  ];

  const handleTimeline = async () => {
    const getTimeline = await api.get("timeline").catch(console.log);
    const timelineData = getTimeline.data.docs;
    const desc = timelineDesc.current.value;
    const month = timelineMonth.current.value;
    const year = timelineYear.current.value;
    const x =
      timelineData.length > 0
        ? timelineData[timelineData.length - 1].x + 100
        : 100;
    const y =
      timelineData.length > 0
        ? timelineData[timelineData.length - 1].y > 0
          ? +timelineData[timelineData.length - 1].y * 2
          : -timelineData[timelineData.length - 1].y
        : 100;
    const postTimeline = await api
      .post("timeline", {
        desc: desc,
        month: month,
        year: year,
        x,
        y,
      })
      .catch((error) => console.log(error));
    if (postTimeline) {
      const getTimeline = await api.get("timeline").catch(console.log);
      setTimeline(getTimeline.data.docs);
    }
  };

  const handleTimelineDelete = async (e) => {
    const deleteTimeline = api.delete(`timeline/${e._id}`).catch(console.log);
    if (deleteTimeline) {
      const getTimeline = await api.get("timeline").catch(console.log);
      setTimeline(getTimeline.data.docs);
    }
  };

  const handleEdit = () => {
    const Edit = timeline.map((item, index) => {
      return (
        <tr key={index} style={{ height: "170px" }}>
          <td style={{ width: "100%", display: "flex" }}>
            <div
              style={{
                width: "50%",
                height: "120px",
                fontSize: "1rem",
                padding: ".5rem",
              }}
            >
              <h3>PT</h3>
              <textarea
                style={{
                  width: "100%",
                  height: "100%",
                  padding: "5px",
                  fontSize: "1rem",
                  borderRadius: "10px",
                  border: "1px solid #ccc",
                  marginRight: "1rem",
                }}
                defaultValue={item.desc}
              />
            </div>
            <div
              style={{
                width: "50%",
                height: "120px",
                fontSize: "1rem",
                padding: ".5rem",
              }}
            >
              <h3>EN</h3>
              <textarea
                style={{
                  width: "100%",
                  height: "100%",
                  padding: "5px",
                  fontSize: "1rem",
                  borderRadius: "10px",
                  border: "1px solid #ccc",
                  marginRight: "1rem",
                }}
                defaultValue={item.desc}
              />
            </div>
          </td>
          <td style={{ width: "2rem" }}>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                marginTop: "1.7rem",
              }}
            >
              <select
                style={{
                  width: "5rem",
                  height: "40px",
                  borderRadius: "10px",
                  border: "1px solid #ccc",
                  padding: "5px",
                  margin: ".5rem",
                }}
                value={item.month}
              >
                {SelectItems.map((month) => {
                  console.log(month.pt, item.month);
                  return month.pt === item.month[0] ? (
                    <option key={month} selected value={month}>
                      {month.pt}
                    </option>
                  ) : (
                    <option key={month} value={month}>
                      {month.pt}
                    </option>
                  );
                })}
              </select>
              <input
                style={{
                  width: "5rem",
                  height: "40px",
                  borderRadius: "10px",
                  border: "1px solid #ccc",
                  padding: "5px",
                  margin: ".5rem",
                }}
                ref={timelineYear}
                defaultValue={item.year}
                type="number"
                min={2000}
                max={2030}
              />
            </div>
          </td>
          <td style={{ width: "3rem" }}>
            <Button onClick={() => handleTimelineDelete(item)}>Editar</Button>
          </td>
        </tr>
      );
    });
    return Edit;
  };
  return (
    <div className="pageContentStructure">
      <div className="pageTopBar">
        <label>TIMELINE</label>
        <div>
          {/* <input
            style={{
              width: "30%",
              height: "3rem",
              borderRadius: "15px",
              marginRight: "1%",
            }}
            placeholder="PROJECT"
            ref={timelineName}
            type="text"
          /> */}
          <input
            style={{
              width: "70%",
              height: "3rem",
              borderRadius: "15px",
              marginRight: "1%",
              padding: "5px",
            }}
            placeholder="Description"
            ref={timelineDesc}
            type="text"
          />
          <select
            style={{
              width: "10%",
              height: "3rem",
              borderRadius: "15px",
              marginRight: "1%",
              padding: "5px",
            }}
            ref={timelineMonth}
          >
            {SelectItems.map((item) => {
              return (
                <option key={item} value={item}>
                  {item.pt}
                </option>
              );
            })}
          </select>
          <input
            style={{
              width: "10%",
              height: "3rem",
              borderRadius: "15px",
              marginRight: "1%",
              padding: "5px",
            }}
            ref={timelineYear}
            defaultValue={2020}
            type="number"
            min={2000}
            max={2030}
          />
          <button
            style={{ width: "6%", height: "3rem", borderRadius: "15px" }}
            onClick={handleTimeline}
          >
            Add
          </button>
        </div>
      </div>

      <Divider />
      <div className="tableContent">
        <table style={{ width: "100%" }}>{timeline && handleEdit()}</table>
      </div>
    </div>
  );
}

export default Timeline;
