import React from "react";
import { Route, Redirect } from "react-router-dom";
import { useStore } from "../../context/storeContext";

const RoutesPrivate = ({ children, ...rest }) => {
  const { login } = useStore();
  // const { setLoginNav } = useLocalDB();

  return (
    <>
      <Route
        {...rest}
        render={() => (login ? children : <Redirect to="/login" />)}
      />
    </>
  );
};
export default RoutesPrivate;
