import React, { useEffect } from "react";
import { useStore } from "../../context/storeContext";
import Vector from "../../assets/img/Vector.png";

function Project() {
  const { page, news, lang, language } = useStore();
  const text = language[lang].projects;

  return (
    <div
      className="page-project"
      style={{
        transition: page === 4 ? "opacity ease 3s" : "opacity ease .5s",
        opacity: page === 4 ? 1 : 0,
      }}
    >
      <div className="page-project-content">
        <div className="page-project-content-title">
          <div className="page-project-content-title-item">
            <p>{text[0]}</p>
            <div
              className="hue-dot"
              style={{
                background: "yellow",
                filter: "hue-rotate(-5deg) brightness(1)",
              }}
            />
          </div>
          <div className="page-project-content-title-item item-1">
            <p>{text[1]}</p>
            <div
              className="hue-dot"
              style={{
                background: "#01A4FF",
                filter: "hue-rotate(-120deg) invert(100%)",
              }}
            />
          </div>
          <div className="page-project-content-title-item item-2">
            <p>{text[2]}</p>
            <div
              className="hue-dot"
              style={{
                background: "#55f",
              }}
            />
          </div>
          <div className="page-project-content-title-item item-3">
            <p>{text[3]}</p>
            <div
              className="hue-dot"
              style={{
                background: "rgb(72, 197, 255)",
              }}
            />
          </div>
        </div>
        {news &&
          news.map((item, index) => {
            console.log(item);
            return (
              <div className="projects" key={index}>
                <div className="projects-imageBox">
                  <img src={item.img} alt="project logo" />
                </div>

                <div className="projects-progressBar">
                  <div className="progress-bar">
                    <div
                      className="progress-bar-vector"
                      style={{
                        // width: `${25 * item.prog}%`,
                        // width: "100%",
                        backgroundImage: `url(${Vector})`,
                        backgroundSize: "cover",
                        borderRadius: "15px",
                        transition: "margin ease 3s",
                        marginRight:
                          page === 4 ? `${100 - 25 * item.prog}%` : "100%",
                      }}
                    />
                  </div>
                </div>
              </div>
            );
          })}
      </div>
    </div>
  );
}

export default Project;
