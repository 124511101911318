import React, { useState, useRef, useEffect } from "react";
import { useStore } from "../../context/storeContext";
import Divider from "@mui/material/Divider";
import api from "../../service/api";
import { Button } from "@mui/material";

function Team() {
  const name = useRef([]);
  const desc = useRef();
  const { team, setTeam } = useStore();
  const [base64Img, setBase64Img] = useState();

  useEffect(() => {
    const getTeam = async () => {
      const getTeamCrew = await api.get("team").catch(console.log);
      return setTeam(getTeamCrew.data.docs);
    };
    getTeam();
  }, []);

  const fetchTeam = async () => {
    const getTeamCrew = await api.get("team").catch(console.log);
    return setTeam(getTeamCrew.data.docs);
  };

  const convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  // const handleAddTeamMate = async (e) => {
  //   e.preventDefault();
  //   const userName = name.current.value;
  //   const img = base64Img;
  //   const descName = desc.current.value;
  //   const getApi = await api
  //     .post("team", { name: userName, img: img, desc: descName })
  //     .catch((error) => console.log(error));
  //   if (getApi) {
  //     fetchTeam();
  //   }
  // };

  const editValue = async (e, item, index) => {
    e.preventDefault();
    const userName = name.current[index].value;
    const img = base64Img;
    const attTeam = await api
      .put(`team/${item._id}`, { name: userName, img: img })
      .catch(console.log);
    console.log(attTeam);
    fetchTeam();
    return attTeam;
  };

  async function getBase64Img(e) {
    const file = e.target.files[0];
    const base64 = await convertBase64(file);
    setBase64Img(base64);
  }

  return (
    <div
      className="pageContentStructure"
      style={{ overflowX: "hidden", overflowY: "auto" }}
    >
      {/* <div
        className="pageTopBar"
        style={{
          minHeight: "7rem",
          margin: "1rem",
          borderBottomLeftRadius: "15px",
          borderBottomRightRadius: "15px",
        }}
      >
        <form
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            marginLeft: "4rem",
            marginTop: "1rem",
          }}
          onSubmit={(e) => handleAddTeamMate(e)}
        >
          <input
            style={{
              width: "31%",
              height: "3rem",
              border: "1px solid #eee",
              borderRadius: "15px",
              marginRight: "1%",
              padding: "5px",
            }}
            ref={name}
            type="text"
          />

          <input
            style={{
              width: "15%",
              height: "3rem",
              marginRight: "1%",
            }}
            placeholder="IMG"
            type="file"
            onChange={(e) => getBase64Img(e)}
          />
          <input
            style={{
              width: "31%",
              height: "3rem",
              borderRadius: "15px",
              marginRight: "1%",
            }}
            placeholder="DESCRIÇÃO"
            ref={desc}
            type="text"
          />
          <Button
            type="submit"
            style={{
              width: "8%",
              height: "3rem",
              borderRadius: "15px",
            }}
          >
            Edit
          </Button>
        </form>
      </div> */}
      <div>
        {team &&
          team.map((item, index) => {
            return (
              <>
                <div
                  className="pageTopBar"
                  style={{
                    minHeight: "7rem",
                    margin: "1rem",
                    borderBottomLeftRadius: "15px",
                    borderBottomRightRadius: "15px",
                  }}
                  key={index}
                >
                  <label>{item.desc}</label>

                  <form
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      marginLeft: "4rem",
                      marginTop: "1rem",
                    }}
                    onSubmit={(e) => editValue(e, item, index)}
                  >
                    <img
                      style={{
                        height: "4rem",
                        marginTop: "-15px",
                        marginRight: "2rem",
                      }}
                      src={item.img}
                      alt="imgTeam"
                    />
                    <input
                      style={{
                        width: "31%",
                        height: "3rem",
                        border: "1px solid #eee",
                        borderRadius: "15px",
                        marginRight: "1%",
                        padding: "5px",
                      }}
                      placeholder={item.name}
                      defaultValue={item.name}
                      ref={(e) => (name.current[index] = e)}
                      type="text"
                    />

                    <input
                      style={{
                        width: "15%",
                        height: "3rem",
                        marginRight: "1%",
                      }}
                      placeholder="IMG"
                      type="file"
                      onChange={(e) => getBase64Img(e)}
                    />
                    {/* <input
                      style={{
                        width: "31%",
                        height: "3rem",
                        borderRadius: "15px",
                        marginRight: "1%",
                      }}
                      placeholder="DESCRIÇÃO"
                      ref={desc}
                      type="text"
                    /> */}
                    <Button
                      type="submit"
                      style={{
                        width: "8%",
                        height: "3rem",
                        borderRadius: "15px",
                      }}
                    >
                      Edit
                    </Button>
                  </form>
                </div>
                {/* <Divider style={{ margin: "2px 0" }} /> */}
              </>
            );
          })}
      </div>
    </div>
  );
}

export default Team;
