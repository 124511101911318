import React, { useEffect, useState } from "react";
import { useStore } from "../../context/storeContext";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import ReactWhatsapp from "react-whatsapp";

function Participation() {
  const { page, lang, language } = useStore();
  const text = language[lang].partner;

  return (
    <section
      className="page-participate"
      style={{
        transition:
          page === 9
            ? "transform ease .5s, opacity ease .5s"
            : "transform ease .5s, opacity ease .3s",
        transform: `scale(${page === 9 ? 1.01 : 10})`,
        opacity: page === 9 ? 1 : 0,
      }}
    >
      <div className="page-participate-content-text">
        <div className={page === 9 && `box-title-1`}>
          <p>{text[0]}</p>
        </div>
        <br />
        <div className={page === 9 && `box-title-2`}>
          <p>{text[1]}</p>
        </div>
        <br />
        <div className={page === 9 && `box-title-3`}>
          <p>{text[2]}</p>
        </div>
        <br />
        <div className={page === 9 && `box-title-4`}>
          <p>{text[3]}</p>
        </div>
      </div>

      <div className="page-participate-whatsapp-box">
        <p>{text[4]}</p>
        <ReactWhatsapp
          number="+55-34-9-9921-0415"
          style={{
            background: "none",
            border: "none",
            display: "flex",
            alignItems: "center",
            fontSize: "1.7rem",
            cursor: "pointer",
          }}
        >
          <WhatsAppIcon
            style={{
              width: "3rem",
              height: "3rem",
              color: "white",
              marginRight: "1rem",
            }}
          />
          <p style={{ color: "#fff" }}>{text[5]}</p>
        </ReactWhatsapp>
      </div>
      <div style={{ opacity: page === 9 ? 1 : 0 }}>
        <span
          className="gradient-stroke-1p8"
          style={{
            transition: "margin ease 3s",
            marginTop: page === 9 ? 0 : "7rem",
            marginLeft: page === 9 ? 0 : "3.5rem",
          }}
        />
        <span
          className="gradient-stroke-2p8"
          style={{
            transition: "margin ease 3s",
            marginTop: page === 9 ? 0 : "5rem",
            marginLeft: page === 9 ? 0 : "2.5rem",
          }}
        />
        <span
          className="gradient-stroke-3p8"
          style={{
            transition: "margin ease 3s",
            marginTop: page === 9 ? 0 : "5rem",
            marginLeft: page === 9 ? 0 : "2.5rem",
          }}
        />
        <span
          className="gradient-stroke-4p8"
          style={{
            transition: "margin ease 3s",
            marginTop: page === 9 ? 0 : "5rem",
            marginLeft: page === 9 ? 0 : "2.5rem",
          }}
        />
        <span
          className="gradient-stroke-5p8"
          style={{
            transition: "margin ease 3s",
            marginTop: page === 9 ? 0 : "5rem",
            marginLeft: page === 9 ? 0 : "2.5rem",
          }}
        />
        <span
          className="gradient-stroke-6p8"
          style={{
            transition: "margin ease 3s",
            marginTop: page === 9 ? 0 : "5rem",
            marginLeft: page === 9 ? 0 : "2.5rem",
          }}
        />
        <span
          className="gradient-stroke-7p8"
          style={{
            transition: "margin ease 3s",
            marginTop: page === 9 ? 0 : "10rem",
            marginLeft: page === 9 ? 0 : "5rem",
          }}
        />
        <span
          className="gradient-stroke-8p8"
          style={{
            transition: "margin ease 3s",
            marginTop: page === 9 ? 0 : "5rem",
            marginLeft: page === 9 ? 0 : "2.5rem",
          }}
        />
      </div>
    </section>
  );
}

export default Participation;
