import React, { useState, useEffect } from "react";
import { useStore } from "../../context/storeContext";
// import Language from "../../assets/lang";
import api from "../../service/api";
import { Button } from "@mui/material";

function Textos() {
  const [mod, setMod] = useState(false);
  const { setLanguage, language } = useStore();
  const [ptLang, setPtLang] = useState([]);
  const [enLang, setEnLang] = useState([]);

  useEffect(() => {
    if (language) {
      setEnLang(language[0].en);
      setPtLang(language[0].pt);
    }
  }, [language]);

  useEffect(() => {
    return fetchLang();
  }, []);

  const fetchLang = async () => {
    // const postLang = await api
    //   .post("lang", { ...Language })
    //   .catch(console.log);
    // if (postLang) {
    //   console.log(postLang);
    //   return postLang;
    // }
    const lang = await api.get("lang").catch(console.log);
    if (lang) {
      return setLanguage(lang.data.docs);
    }
  };
  const handleCancel = async () => {
    const getfetch = await fetchLang();
    setMod();
  };

  const handleConfirm = async () => {
    const attLang = await api
      .put("lang/618f61c13c5aca0004424ddd", { en: enLang, pt: ptLang })
      .catch(console.log);
    if (attLang) {
      setMod(false);
      alert("Modificação adicionada ao banco de dados.");
      return attLang;
    }
  };

  const getInputs = (obj) => {
    const getLang = enLang[obj].map((item, index) => {
      return (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            marginLeft: "4rem",
            marginTop: "1rem",
          }}
        >
          <h3>EN</h3>
          <input
            style={{
              width: "49%",
              height: "3rem",
              border: "1px solid #eee",
              borderRadius: "15px",
              marginRight: "1%",
              padding: "5px",
            }}
            onChange={(e) => {
              enLang[obj].splice(index, 1, e.target.value);
              setMod(true);
            }}
            defaultValue={item}
            type="text"
          />
          <h3>PT</h3>
          <input
            style={{
              width: "49%",
              height: "3rem",
              border: "1px solid #eee",
              borderRadius: "15px",
              marginRight: "1%",
              padding: "5px",
            }}
            defaultValue={ptLang[obj][index]}
            onChange={(e) => {
              ptLang[obj].splice(index, 1, e.target.value);
              setMod(true);
            }}
            type="text"
          />
        </div>
      );
    });
    return getLang;
  };

  return (
    <div
      className="pageContentStructure"
      style={{ overflowX: "hidden", overflowY: "auto", padding: "1rem" }}
    >
      <div>
        {language &&
          Object.keys(enLang).map((itemObj, indexObj) => {
            return (
              <>
                <label key={indexObj}>{itemObj.toUpperCase()}</label>
                <div
                  style={{
                    minHeight: "7rem",
                    padding: "1rem",
                  }}
                >
                  {getInputs(itemObj, indexObj)}
                </div>
              </>
            );
          })}
      </div>
      {mod && (
        <div
          style={{
            position: "fixed",
            top: "1rem",
            right: "35%",
            width: "250px",
            height: "80px",
            background: "#eee",
            border: "1px solid #ccc",
            borderRadius: "10px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Button
            style={{
              padding: ".5rem",
              background: "rgba(200,100,100,0.8)",
              color: "white",
              borderRadius: "10px",
              marginRight: "1rem",
            }}
            onClick={handleCancel}
          >
            cancelar
          </Button>
          <Button
            style={{
              padding: ".5rem",
              background: "rgba(100,100,200,0.8)",
              color: "white",
              borderRadius: "10px",
            }}
            onClick={handleConfirm}
          >
            Confirmar
          </Button>
        </div>
      )}
    </div>
  );
}

export default Textos;
