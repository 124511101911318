import { textAlign } from "@mui/system";
import React, { useState } from "react";
import { useStore } from "../../context/storeContext";

const initialLoginValues = { username: "", password: "" };

function Login() {
  const [user, setUser] = useState(initialLoginValues);
  const { setLogin, authData } = useStore();

  const handleInputs = (e) => {
    const { value, name } = e.target;
    return setUser({ ...user, [name]: value });
  };

  const HandleLogin = (e) => {
    e.preventDefault();
    if (
      user.username === authData.username &&
      user.password === authData.password
    ) {
      setLogin(true);
    } else {
      alert("Login ou senha inválidos.");
    }
  };

  return (
    <section
      style={{
        display: "flex",
        width: "100vw",
        height: "100vh",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <form
        style={{
          width: "450px",
          height: "450px",
          border: "1px solid #eee",
          borderRadius: "15px",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
        onSubmit={HandleLogin}
      >
        <h1
          style={{
            width: "250px",
            padding: ".5rem",
            textAlign: "center",
            marginTop: "-2rem",
            marginBottom: "3rem",
            fontSize: "2rem",
          }}
        >
          Login
        </h1>
        <label>USER</label>
        <input
          style={{
            width: "300px",
            padding: ".5rem",
            marginBottom: "1rem",
            borderRadius: "11px",
            fontSize: "1.2rem",
            height: "50px",
            border: "1px solid #ccc",
          }}
          type="text"
          name="username"
          value={user.username}
          required
          autoFocus
          onChange={handleInputs}
        />
        <label>SENHA</label>
        <input
          style={{
            width: "300px",
            height: "50px",
            padding: ".5rem",
            fontSize: "1.2rem",
            marginBottom: "2rem",
            borderRadius: "11px",
            border: "1px solid #ccc",
          }}
          type="password"
          name="password"
          value={user.password}
          required
          onChange={handleInputs}
        />
        <button
          style={{
            width: "300px",
            height: "50px",
            padding: ".5rem",
            margin: ".5rem",
            borderRadius: "11px",
            fontSize: "1.5rem",
            border: "1px solid #ccc",
          }}
          onSubmit={HandleLogin}
        >
          Entrar
        </button>
      </form>
    </section>
  );
}

export default Login;
