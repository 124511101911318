import React, { createContext, useContext, useEffect, useState } from "react";
import api from "../service/api";
import useStorage from "../utils/useStorage";

const StoreContext = createContext();

export default function StoreProvider({ children }) {
  const [page, setPage] = useState();
  const [timeline, setTimeline] = useState();
  const [news, setNews] = useState();
  const [opacity, setOpacity] = useState(0);
  const [lang, setLang] = useState("en");
  const [login, setLogin] = useState(false);
  const [team, setTeam] = useState([]);
  const [language, setLanguage] = useState();
  const authData = { username: "admin", password: "eq9thebesttoken" };

  return (
    <StoreContext.Provider
      value={{
        page,
        setPage,
        timeline,
        setTimeline,
        news,
        setNews,
        opacity,
        setOpacity,
        lang,
        setLang,
        login,
        setLogin,
        authData,
        team,
        setTeam,
        language,
        setLanguage,
      }}
    >
      {children}
    </StoreContext.Provider>
  );
}

export function useStore() {
  const context = useContext(StoreContext);
  if (!context) throw new Error("No Page");
  const {
    page,
    setPage,
    timeline,
    setTimeline,
    news,
    setNews,
    opacity,
    setOpacity,
    lang,
    setLang,
    login,
    setLogin,
    authData,
    team,
    setTeam,
    language,
    setLanguage,
  } = context;
  return {
    page,
    setPage,
    timeline,
    setTimeline,
    news,
    setNews,
    opacity,
    setOpacity,
    lang,
    setLang,
    login,
    setLogin,
    authData,
    team,
    setTeam,
    language,
    setLanguage,
  };
}
