import React from "react";
import { useStore } from "../../context/storeContext";

function Marker() {
  const { page, setPage } = useStore();
  return (
    <div
      style={{
        position: "fixed",
        top: "44.5%",
        right: "11px",
        display: "flex",
        flexDirection: "column",
        zIndex: 1000,
        alignItems: "flex-end",
      }}
    >
      {[1, 2, 3, 4, 5, 6, 7, 8, 9].map((item) => {
        return (
          <span
            style={{
              width: page === item ? "50px" : "25px",
              height: "2px",
              borderRadius: "10px",
              zIndex: 1,
              backgroundColor: page === item ? "#ccc" : "#aaa",
              margin: "8px",
              transition: "all ease .8s",
            }}
          />
        );
      })}
    </div>
  );
}

export default Marker;
