import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";
import App from "../App/App";
import Login from "../../pages/admin/login";
import RoutesPrivate from "./Private";
import Admin from "../../pages/admin";
import { useStore } from "../../context/storeContext";
import WhitePaperPt from "../../pages/distribution/whitePaper-pt";
import WhitePaperEn from "../../pages/distribution/whitePaper-en";

function Routes() {
  const { login } = useStore();
  const getLogged = () => {
    if (login) {
      return <Redirect from="/login" to="/admin" />;
    }
  };
  return (
    <Router>
      {getLogged()}
      <Switch>
        <Route path="/" exact={true} component={App} />
        <Route path="/admin">
          <RoutesPrivate>
            <Admin />
          </RoutesPrivate>
        </Route>
        <Route path="/login" component={Login} />
        <Route path="/whitepaper-pt" component={WhitePaperPt} />
        <Route path="/whitepaper-en" component={WhitePaperEn} />
      </Switch>
    </Router>
  );
}

export default Routes;
