import React, { useEffect } from "react";
import RocketImage from "../../assets/img/rocket.png";
import { useStore } from "../../context/storeContext";
import LeftBox from "../../assets/img/leftBox.png";
import rightBox from "../../assets/img/rightBox.png";
import RocketIcon from "../../assets/img/foguete.png";
import DiamondIcon from "../../assets/img/diamante.png";

function Mission() {
  const { page, lang, language } = useStore();
  const text = language[lang].mission;

  return (
    <div className="page-mission">
      <div className="page-mission-left">
        <div
          className="m-card-left"
          style={{
            transition: "margin ease 3s, opacity ease 3s",
            marginTop: page >= 2 ? 0 : "5rem",
            opacity: page === 2 ? 1 : 0,
          }}
        >
          <img src={RocketIcon} alt="Rocket Image" />
          <h1>{text[3]}</h1>
          <ul>
            <li>{text[4]}</li>
            <li>{text[5]}</li>
            <li>{text[6]}</li>
          </ul>
        </div>
        <div
          className="m-card-right"
          style={{
            transition: "margin ease 3s, opacity ease 3s",
            marginTop: page >= 2 ? 0 : "-5rem",
            opacity: page === 2 ? 1 : 0,
          }}
        >
          <img src={DiamondIcon} alt="Diamond Image" />
          <h1>{text[7]}</h1>
          <ul>
            <li>{text[8]}</li>
            <li>{text[9]}</li>
            <li>{text[10]}</li>
          </ul>
        </div>
      </div>

      <div className="page-mission-right">
        <img
          className="m-rocket"
          style={{
            transition:
              page === 2
                ? "margin ease 3s, opacity ease 3s"
                : "margin ease .7s, opacity ease .7s",
            marginTop: page === 2 ? 0 : "7rem",
            marginLeft: page === 2 ? 0 : "7rem",
            opacity: page === 2 ? 1 : 0,
          }}
          src={RocketImage}
          alt="rocket-img"
        />
        <span
          // className={
          //   page === 2 &&
          //   `glow-ball-large animate__animated animate__rotateIn animate__slow`
          // }
          className="glow-ball-large"
          style={{
            transition: page === 2 ? "transform ease 3s" : "transform ease .5s",
            transform: `rotate(${page === 2 ? "0" : "90deg"})`,
          }}
        />
        <span className="glow-ball-2" />
        <span className="glow-ball-3" />
      </div>
      <span
        className="gradient-stroke-one"
        style={{
          transition: "margin ease 3s",
          marginTop: page === 2 ? 0 : "7rem",
          marginLeft: page === 2 ? 0 : "3.5rem",
        }}
      />
      <span
        className="gradient-stroke-two"
        style={{
          transition: "margin ease 3s",
          marginTop: page === 2 ? 0 : "5rem",
          marginLeft: page === 2 ? 0 : "2.5rem",
        }}
      />
      <span
        className="gradient-stroke-three"
        style={{
          transition: "margin ease 3s",
          marginTop: page === 2 ? 0 : "5rem",
          marginLeft: page === 2 ? 0 : "2.5rem",
        }}
      />
      <span
        className="gradient-stroke-four"
        style={{
          transition: "margin ease 3s",
          marginTop: page === 2 ? 0 : "5rem",
          marginLeft: page === 2 ? 0 : "2.5rem",
        }}
      />
      <span
        className="gradient-stroke-five"
        style={{
          transition: "margin ease 3s",
          marginTop: page === 2 ? 0 : "5rem",
          marginLeft: page === 2 ? 0 : "2.5rem",
        }}
      />
      <span
        className="gradient-stroke-six"
        style={{
          transition: "margin ease 3s",
          marginTop: page === 2 ? 0 : "5rem",
          marginLeft: page === 2 ? 0 : "2.5rem",
        }}
      />
      <span
        className="gradient-stroke-seven"
        style={{
          transition: "margin ease 3s",
          marginTop: page === 2 ? 0 : "10rem",
          marginLeft: page === 2 ? 0 : "5rem",
        }}
      />
      <span
        className="gradient-stroke-eight"
        style={{
          transition: "margin ease 3s",
          marginTop: page === 2 ? 0 : "5rem",
          marginLeft: page === 2 ? 0 : "2.5rem",
        }}
      />
      <span
        className="gradient-stroke-nine"
        style={{
          transition: "margin ease 3s",
          marginTop: page === 2 ? 0 : "5rem",
          marginLeft: page === 2 ? 0 : "2.5rem",
        }}
      />
      <div className="page-mission-waterMark-text">
        <p>{text[0]}</p>
        <p>{text[1]}</p>
        <p>{text[2]}</p>
      </div>
    </div>
  );
}

export default Mission;
