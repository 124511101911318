import React, { useEffect, useRef, useState } from "react";
import { useStore } from "../../context/storeContext";
// import {
//   FlexibleXYPlot,
//   LineMarkSeries,
//   CustomSVGSeries,
//   MarkSeries,
// } from "react-vis";
import api from "../../service/api";
import "react-vis/dist/style.css";
import VectorLeft from "../../assets/img/VectorL.png";
import VectorRight from "../../assets/img/VectorR.png";
import { keyframes } from "styled-components";

import pointer from "../../assets/img/Pointer.png";
import PathBackground from "../../assets/img/pathBackground.svg";
import "./timeline.scss";

function Timeline() {
  const { page, lang, language } = useStore();
  const [padding, setPadding] = useState(20);
  const [opacity, setOpacity] = useState(0);
  const [box, setBox] = useState();
  const [data, setData] = useState([]);
  const [dataSign, setDataSign] = useState([]);
  const [mouseCoordenates, setMouseCoordenates] = useState(false);
  const [boxPosition, setBoxPosition] = useState();
  // const text = language[lang].timeline;
  const win = window.event;

  const dash = keyframes`
  from{
    stroke-dasharray: 3000;
    stroke-dashoffset: 5000;
  }
  to{
    stroke-dasharray: 8000;
    stroke-dashoffset: 0;
  }
  `;

  useEffect(() => {
    const GetData = async () => {
      const apiGet = await api.get("timeline").catch(console.log);
      if (apiGet) {
        setData(apiGet.data.docs);
      }
    };
    return GetData();
  }, []);

  useEffect(() => {
    if (win) {
      setMouseCoordenates({ x: win.clientX, y: win.clientY });
    }
  }, [box]);

  // useEffect(() => {
  //   if (data) {
  //     setDataSign(
  //       data.map((item) => {
  //         if (item.desc) {
  //           return {
  //             desc: item.desc,
  //             month: item.month,
  //             x: item.x,
  //             y: item.y,
  //             name: item.name,
  //             year: item.year,
  //             customComponent: (row, e, r) => {
  //               return (
  //                 <g
  //                   style={{
  //                     transform:
  //                       "rotate(-90deg) matrix3d(2,0,0.00,0.00,0.00,3,0.00,0,0,0,0,0,-50,-140,0,1) rotateX(30deg)",
  //                     // pointerEvents: "none",
  //                   }}
  //                 >
  //                   {row.desc[0].length > 0 && (
  //                     <foreignObject width="400" height="400">
  //                       <div xmlns="http://www.w3.org/1999/xhtml" style={{}}>
  //                         <img
  //                           src={pointer}
  //                           style={{ width: "3rem" }}
  //                           alt="pointer"
  //                         />
  //                       </div>
  //                     </foreignObject>
  //                   )}
  //                 </g>
  //               );
  //             },
  //           };
  //         }
  //       })
  //     );
  //   }
  // }, [data]);

  useEffect(() => {
    if (page === 3) {
      setPadding(20);
      setOpacity(1);
    } else if (page < 3) {
      setOpacity(0);
      setTimeout(() => {
        setPadding(120);
      }, 500);
    } else if (page > 3) {
      setOpacity(0);
      setTimeout(() => {
        setPadding(120);
      }, 500);
    }
  }, [page]);

  const handleMouseMove = (e) => {
    if (page === 3) {
      const getY = e.screenY / 30;
      const getX = (e.screenX - 600) / 15;
      // if (mouse === true) {
      // setTime(300);
      // if (time === 0) {
      // setPadding(-getY);
      // setPaddingLeft(-getX);
      // }
      // }
    }
  };

  return (
    <div
      style={{
        width: "100vw",
        height: "100vh",
        marginTop: "-16rem",
        color: "black",
        position: "relative",
        position: "relative",
        // transform:
        //   "matrix3d(1,0,0.00,0.00,0.00,1,0.00,-0.00006,0,0,1,0,0,0,0,1.03) ",
        // cursor: mouse ? "grabbing" : "grab",
      }}
      onMouseMove={handleMouseMove}
      // onMouseDown={handleGrab}
      // onMouseUp={handleUnGrab}
    >
      <image
        src={PathBackground}
        alt="background"
        viewBox="10 10 250 250"
        preserveAspectRatio="none"
      />
      {page === 3 ? (
        <svg
          style={{
            marginTop: "16rem",
            animation: page === 3 ? `${dash} 2s linear` : null,
            position: "absolute",
            zIndex: 1,
          }}
          viewBox="-100 105 2050 1200"
        >
          <path
            d={`M1133,265 C900,280 1530,293 915,307 650,330 2760,415 630,495 -280,540 4900,695 -1100,1355
            M1133,265 C890,280 1470,285 905,299 540,350 2570,415 340,455 -250,590 3900,805 -1100,955
            `}
            // d={`M1133,265 C990,280 1530,293 915,305 650,330 2760,425 630,495 -280,540 4900,695 -1100,1355
            // M1133,265 C900,280 1500,293 895,300 570,350 2770,422 600,485 -320,560 4955,723 -1180,1328
            // M1133,265 C920,280 1500,293 1005,300 510,350 2738,432 590,467 -380,610 4947,720 -1180,1270
            // M1133,265 C920,280 1500,293 1005,300 460,350 2720,432 560,462 -390,610 5018,785 -1300,1200
            // M1133,265 C920,280 1500,293 1005,300 390,340 2750,412 470,475 -165,550 4918,745 -1200,1300
            // M1133,265 C920,280 1500,293 1005,300 460,350 2710,402 440,460 -135,610 4918,745 -1150,1250
            // M1133,265 C890,280 1470,288 915,299 560,350 2570,415 370,455 -250,540 3900,805 -1100,955
            // `}
            style={{ stroke: "#ccc", strokeWidth: 4, fill: "none" }}
          />
        </svg>
      ) : null}
      {/* <FlexibleXYPlot
        style={{
          transition:
            page === 3
              ? "margin ease 1s, padding ease 1s, opacity ease 3s "
              : "ease .2s, opacity ease 0.2s ",
          "-webkit-transition": ".3s",
          "-webkit-transition-timing-function": "cubic-bezier(0.42,0,0.58,1)",
          "transition-timing-function": "cubic-bezier(0.42,0,0.58,1)",
          transform:
            "rotate(90deg) matrix3d(0.3,0,0.00,-0.0006,0.00,1,0.00,0,0,0,1,0,0,0,0,1) ",
          paddingLeft: `25%`,
          marginLeft: `10rem`,
          opacity: opacity,
        }}
      >
        <LineMarkSeries
          className={`lineMarks animateactive`}
          style={{
            animation: page === 3 ? `${dash} 2s linear` : null,
          }}
          curve={"curveMonotoneX"}
          lineStyle={{ strokeWidth: "20", padding: "5rem" }}
          stroke="grey"
          data={data}
          fill="transparent"
          markStyle={{ opacity: 1, border: "none", outline: "none" }}
        />
        <MarkSeries
          data={dataSign}
          fill="transparent"
          size={10}
          stroke="transparent"
          onValueMouseOver={(e, c) => {
            setBox(e);
            setBoxPosition({
              x: c.event.clientX - 40,
              y: c.event.clientY + 35,
            });
          }}
          onValueMouseOut={() => setBox(null)}
        />
      </FlexibleXYPlot> */}
      {box && (
        <div
          style={{
            position: "absolute",
            color: "black",
            top: boxPosition.y,
            left: boxPosition.x,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            pointerEvents: "none",
            // background: "black",
          }}
        >
          <div
            style={{
              borderRadius: "10px",
              background:
                "linear-gradient(267.77deg, #0282FD -2.46%, #474FC2 75.07%)",
              width: "150px",
              height: "55px",
              position: "absolute",
              top: "-2rem",
              left: box.y < 0 ? "-9rem" : "5.4rem",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-evenly",
              fontSize: "1.6rem",
              color: "white",
              padding: "5px",
              paddingTop: "10px",
            }}
          >
            <p style={{ fontFamily: "gilroy-thin" }}>{box.month[lang]}</p>
            <p>{box.year}</p>
          </div>
          <p
            style={{
              position: "absolute",
              top: "2rem",
              left: box.y < 0 ? "-10.8rem" : "3.8rem",
              fontSize: "10px",
              textAlign: "center",
              width: "210px",
              // background: "black",
            }}
          >
            {box.desc[lang]}
          </p>
          {box.y < 0 ? (
            <img
              src={VectorLeft}
              alt="graph"
              style={{
                maxHeight: "150px",
                maxWidth: "20px",
                marginLeft: "-10px",
              }}
            />
          ) : (
            <img
              src={VectorRight}
              alt="graph"
              style={{
                maxHeight: "150px",
                maxWidth: "20px",
                marginLeft: "20px",
              }}
            />
          )}

          <img src={pointer} style={{ width: "5rem" }} alt="pointer" />
        </div>
      )}
      <div
        style={{
          width: "100%",
          height: "100vh",
          position: "relative",
          marginTop: "1.5%",
          zIndex: 100,
        }}
      >
        {data.map((item, index) => {
          return (
            <div
              key={index}
              className={`graphicMarkers item${index + 1}`}
              style={{
                opacity: page === 3 ? 1 : 0,
                marginTop: page === 3 ? 0 : "-1rem",
                transition:
                  page === 3
                    ? "opacity linear 2s, margin ease 3s"
                    : "opacity ease .3s, margin ease .3s",
              }}
            >
              <div
                className="topBox"
                style={{ left: index % 2 === 0 ? "-9rem" : "5.4rem" }}
              >
                <p style={{ fontFamily: "gilroy-thin" }}>{item.month[lang]}</p>
                <p>{item.year}</p>
              </div>
              <p
                style={{
                  position: "absolute",
                  top: "2rem",
                  left: index % 2 === 0 ? "-10.3rem" : "4rem",
                  fontSize: "11px",
                  textAlign: item.desc[lang].length > 20 ? "justify" : "center",
                  width: "190px",
                  // background: "black",
                }}
              >
                {item.desc[lang]}
              </p>
              {index % 2 === 0 ? (
                <img
                  src={VectorLeft}
                  alt="graph"
                  style={{
                    maxHeight: "130px",
                    maxWidth: "20px",
                    marginLeft: "-10px",
                  }}
                />
              ) : (
                <img
                  src={VectorRight}
                  alt="graph"
                  style={{
                    maxHeight: "130px",
                    maxWidth: "20px",
                    marginLeft: "20px",
                  }}
                />
              )}
              <img src={pointer} style={{ width: "5rem" }} alt="pointer" />
            </div>
          );
          // }
        })}
      </div>
    </div>
  );
}
export default Timeline;
