import React, { useState, useEffect, useRef } from "react";
import { useStore } from "../../context/storeContext";
import Divider from "@mui/material/Divider";
import api from "../../service/api";

function Projects() {
  const projectName = useRef();
  const projectImg = useRef();
  const projectProgress = useRef();
  const { news, setNews } = useStore();
  const [base64Img, setBase64Img] = useState();

  const convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  const handleProject = async () => {
    const name = projectName.current.value;
    const img = base64Img;
    const prog = projectProgress.current.value;
    const getApi = await api
      .post("projects ", { name: name, img: img, prog: prog })
      .catch((error) => console.log(error));
    console.log(getApi);
  };

  const handleProjectDelete = (e) => {
    const newList = news;
    newList.splice(e, 1);
    setNews(newList);
  };

  const editValue = (e, item) => {
    api
      .put(`projects/${item._id}`, { prog: e.target.value })
      .catch(console.log);
  };

  async function getBase64Img(e) {
    const file = e.target.files[0];
    const base64 = await convertBase64(file);
    setBase64Img(base64);
  }
  return (
    <div className="pageContentStructure">
      <div className="pageTopBar">
        <label>PROJECTS</label>
        <div>
          <input
            style={{
              width: "51%",
              height: "3rem",
              borderRadius: "15px",
              marginRight: "1%",
            }}
            placeholder="NAME"
            ref={projectName}
            type="text"
          />
          <input
            style={{
              width: "15%",
              height: "3rem",
              marginRight: "1%",
            }}
            placeholder="IMG"
            ref={projectImg}
            type="file"
            onChange={(e) => getBase64Img(e)}
          />
          <select
            style={{
              width: "23%",
              height: "3rem",
              borderRadius: "15px",
              marginRight: "1%",
            }}
            placeholder="PORCENTAGEM"
            ref={projectProgress}
            type="number"
          >
            <option value={1}>1</option>
            <option value={2}>2</option>
            <option value={3}>3</option>
            <option value={4}>4</option>
          </select>
          <button
            style={{ width: "8%", height: "3rem", borderRadius: "15px" }}
            onClick={handleProject}
          >
            Add
          </button>
        </div>
      </div>
      <Divider style={{ margin: "2px 0" }} />
      <div className="tableContent">
        <table>
          {news
            ? news.map((item, index) => {
                return (
                  <tr key={index} style={{ height: "30px" }}>
                    <img style={{ height: "50px" }} src={item.img} alt="img" />
                    <td>{item.name}</td>
                    <select
                      defaultValue={item.prog}
                      onChange={(e) => editValue(e, item)}
                    >
                      <option value={1}>1</option>
                      <option value={2}>2</option>
                      <option value={3}>3</option>
                      <option value={4}>4</option>
                    </select>
                    <td>
                      <button onClick={() => handleProjectDelete(index)}>
                        X
                      </button>
                    </td>
                  </tr>
                );
              })
            : null}
        </table>
      </div>
    </div>
  );
}

export default Projects;
