import React from "react";
import ReactDOM from "react-dom";
import "./index.scss";
// import App from "./components/App/App";
import Routes from "./components/Routes/routes";
import StoreProvider from "./context/storeContext";

ReactDOM.render(
  <StoreProvider>
    <Routes />
  </StoreProvider>,
  document.getElementById("root")
);
