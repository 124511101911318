import React from "react";
import { useStore } from "../../context/storeContext";
import { ReactComponent as LinhaReta } from "../../assets/img/Vector22.svg";
import { ReactComponent as LinhaTecnologia } from "../../assets/img/Vector27.svg";
import { ReactComponent as LinhaDireita } from "../../assets/img/Vector26.svg";

function Team() {
  const { page, team } = useStore();
  if (!team.length > 0) return <p>Loading...</p>;
  return (
    <div
      className="page-team"
      style={
        {
          // opacity: page === 7 ? 1 : 0,
          // transition: "opacity ease 3s",
        }
      }
    >
      <div>
        <h1
          style={{
            position: "absolute",
            top: "46%",
            left: "3%",
            fontFamily: "gilroy-bold",
            letterSpacing: "2px",
          }}
        >
          DIRETORIA
        </h1>
        <div className="teamBox" style={{ left: "12%", top: "39%" }}>
          <LinhaReta className="linhaReta" />
          <img
            src={team[0].img}
            alt="diretorPresidente"
            style={{ padding: "2px" }}
            className="equipImg"
          />
          <div className="TeamMateDesc">
            <h1>{team[0].name}</h1>
            <p>{team[0].desc}</p>
          </div>
        </div>
        <div className="teamBox" style={{ left: "20%", top: "51%" }}>
          <LinhaReta className="linhaReta" />
          <img
            src={team[1].img}
            alt="diretorJuridico"
            style={{ padding: "2px" }}
            className="equipImg"
          />
          <div className="TeamMateDesc">
            <h1>{team[1].name}</h1>
            <p>{team[1].desc}</p>
          </div>
        </div>
        <div className="teamBox" style={{ left: "31%", top: "40%" }}>
          <LinhaReta className="linhaReta" />
          <img
            src={team[2].img}
            alt="diretorFinanceiro"
            style={{ padding: "2px" }}
            className="equipImg"
          />
          <div
            className="TeamMateDesc"
            style={{ left: "-10rem", top: "7rem", padding: "2px" }}
          >
            <h1>{team[2].name}</h1>
            <p>{team[2].desc}</p>
          </div>
        </div>
        <div className="teamBox" style={{ left: "39%", top: "51%" }}>
          <LinhaReta className="linhaReta" />
          <img
            src={team[3].img}
            alt="diretorOperacional"
            style={{ padding: "2px" }}
            className="equipImg"
          />
          <div
            className="TeamMateDesc"
            style={{ left: "-7rem", top: "7rem", padding: "2px" }}
          >
            <h1>{team[3].name}</h1>
            <p>{team[3].desc}</p>
          </div>
        </div>
      </div>

      <div
        style={{
          position: "absolute",
          width: "450px",
          height: "450px",
          top: "32%",
          left: "50%",
          transform: "scale(.85)",
        }}
      >
        <h1
          style={{
            position: "absolute",
            top: "-20%",
            left: "27%",
            fontSize: "2rem",
            fontFamily: "gilroy-bold",
          }}
        >
          TECNOLOGIA
        </h1>
        <LinhaTecnologia
          style={{
            position: "absolute",
            left: "-2%",
            top: "22%",
            width: "450px",
          }}
        />
        <div className="teamBox" style={{ left: "-1%", top: "62%" }}>
          <img
            src={team[4].img}
            alt="backdev"
            style={{ padding: "2px" }}
            className="equipImg2"
          />
          <div className="TeamMateDesc">
            <h1>{team[4].name}</h1>
            <p>{team[4].desc}</p>
          </div>
        </div>
        <div className="teamBox" style={{ left: "-1%", top: "20%" }}>
          <img
            src={team[5].img}
            alt="uidesign"
            style={{ padding: "2px" }}
            className="equipImg2"
          />
          <div className="TeamMateDesc">
            <h1>{team[5].name}</h1>
            <p>{team[5].desc}</p>
          </div>
        </div>
        <div
          className="teamBox"
          style={{ left: "38%", top: "-10%", flexDirection: "column-reverse" }}
        >
          <img
            src={team[6].img}
            alt="frontdev"
            style={{ left: "38%", top: "0", padding: "2px" }}
            className="equipImg2"
          />
          <div className="TeamMateDesc" style={{ padding: "2px" }}>
            <h1>{team[6].name}</h1>
            <p>{team[6].desc}</p>
          </div>
        </div>
        <div className="teamBox" style={{ left: "78%", top: "20%" }}>
          <img
            src={team[7].img}
            alt="uxdesign"
            style={{ padding: "2px" }}
            className="equipImg2"
          />
          <div className="TeamMateDesc">
            <h1>{team[7].name}</h1>
            <p>{team[7].desc}</p>
          </div>
        </div>
        <div className="teamBox" style={{ left: "78%", top: "62%" }}>
          <img
            src={team[8].img}
            alt="graphicDesign"
            style={{ padding: "2px" }}
            className="equipImg2"
          />

          <div
            className="TeamMateDesc"
            style={{ left: "14.7%", top: "70%", padding: "2px" }}
          >
            <h1>{team[8].name}</h1>
            <p>{team[8].desc}</p>
          </div>
        </div>
        <div className="teamBox" style={{ left: "38%", top: "46%" }}>
          <img
            src={team[9].img}
            alt="scrumMaster"
            style={{ padding: "2px" }}
            className="equipImg2"
          />
          <div
            className="TeamMateDesc"
            style={{ left: "14.7%", top: "70%", padding: "2px" }}
          >
            <h1>{team[9].name}</h1>
            <p>{team[9].desc}</p>
          </div>
        </div>
      </div>
      <h3
        style={{
          position: "absolute",
          top: "25%",
          right: "2%",
          letterSpacing: "2px",
          fontFamily: "gilroy-bold",
        }}
      >
        CENTRO DE SERVIÇO COMPARTILHADO
      </h3>
      <h2
        style={{
          position: "absolute",
          top: "27.5%",
          right: "7%",
          fontFamily: "gilroy-bold",
          letterSpacing: "2px",
        }}
      >
        (CSC EQUALS9)
      </h2>
      <div className="teamBox" style={{ right: "0%", top: "36%" }}>
        <LinhaDireita
          style={{ position: "absolute", top: "35%", right: "0" }}
        />
        <img
          src={team[10].img}
          alt="gerenteAdmin"
          style={{ padding: "2px" }}
          className="equipImg2"
        />
        <div
          className="TeamMateDesc"
          style={{ padding: "2px", marginTop: "-10px", marginLeft: "-13rem" }}
        >
          <h1>{team[10].name}</h1>
          <p>{team[10].desc}</p>
        </div>
      </div>
      <div className="teamBox" style={{ right: "-8rem", top: "50.5%" }}>
        <img
          src={team[11].img}
          alt="analistaAdmin"
          style={{ padding: "2px", marginLeft: "1rem" }}
          className="equipImg2"
        />
        <div
          className="TeamMateDesc"
          style={{ padding: "2px", marginTop: "-10px", marginLeft: "-10.5rem" }}
        >
          <h1>{team[11].name}</h1>
          <p>{team[11].desc}</p>
        </div>
      </div>
      <div className="teamBox" style={{ right: "0%", top: "61%" }}>
        <img
          src={team[12].img}
          alt="contador"
          style={{ padding: "2px" }}
          className="equipImg2"
        />
        <div
          className="TeamMateDesc"
          style={{ padding: "2px", marginTop: "-10px", marginLeft: "-13rem" }}
        >
          <h1>{team[12].name}</h1>
          <p>{team[12].desc}</p>
        </div>
      </div>
      <div className="teamBox" style={{ right: "-6%", top: "72%" }}>
        <img
          src={team[13].img}
          alt="assistAdmin"
          style={{ padding: "2px" }}
          className="equipImg2"
        />
        <div
          className="TeamMateDesc"
          style={{ padding: "2px", marginTop: "-10px", marginLeft: "-13rem" }}
        >
          <h1>{team[13].name}</h1>
          <p>{team[13].desc}</p>
        </div>
      </div>
      <div className="page-team-waterMark-text">
        <h1>TEAM GROUP GROUP</h1>
        <h1>TEAM GROUP GROUP</h1>
        <h1>TEAM GROUP GROUP</h1>
        <h1>TEAM GROUP GROUP</h1>
        <h1>TEAM GROUP GROUP</h1>
        <i>Conheça nosso time</i>
      </div>
    </div>
  );
}

export default Team;
