import React, { useState } from "react";

function Card({ position, size, children, image, imageStyle, popupTxt }) {
  const { w, h } = size;
  const { t, r, b, l } = position;
  const [mouseOver, setMouseOver] = useState(false);
  return (
    <div
      className="card"
      style={{
        transition: "transform ease 1s, margin ease 1s",
        transform: mouseOver ? "scale(1.04)" : "scale(1)",
        marginTop: mouseOver ? "-0.3rem" : 0,
        width: w,
        height: h,
        top: t,
        right: r,
        bottom: b,
        left: l,
      }}
      onMouseOver={() => setMouseOver(true)}
      onMouseOut={() => setMouseOver(false)}
    >
      <div className="inner">
        <div className="front">
          {image && (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <img src={image} alt="image" style={imageStyle} />
            </div>
          )}
        </div>
        <div className="back">{children}</div>
      </div>
      {image && (
        <div
          className="popup"
          style={{
            position: "absolute",
            top: 0,
            width: "100%",
            display: "flex",
            justifyContent: "center",
            marginTop: mouseOver ? "-1.6rem" : 0,
            opacity: mouseOver ? 1 : 0,
            transition: mouseOver
              ? "margin ease .6s,opacity 2s ease"
              : "margin ease 0.5s,opacity 0.3s ease",
          }}
        >
          {popupTxt}
        </div>
      )}
    </div>
  );
}

export default Card;
