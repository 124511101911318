import React, { useEffect, useState } from "react";
import MarcaDagua from "../../assets/img/MarcaDagua.png";
import LogoEquals9 from "../../assets/img/LogoEquals9.png";
import Typical from "react-typical";
import { useStore } from "../../context/storeContext";
import "animate.css";

function Home() {
  const { page, lang, language } = useStore();
  const [text, setText] = useState();
  const textTrad = language[lang].index;

  useEffect(() => {
    setText();
    setTimeout(() => {
      setText(
        <Typical
          loop={Infinity}
          wrapper="b"
          steps={[textTrad[0], 1500, textTrad[1], 1500, textTrad[2], 1500]}
        />
      );
    }, 100);
  }, [lang]);
  return (
    <div
      className="page-home"
      style={{
        transition: page === 1 ? "opacity ease 2s" : "opacity ease .5s",
        opacity: page === 1 ? 1 : 0,
      }}
    >
      <div className="page-home__content-text">
        <span>{text}</span>
        <div
          className={`page-home__content-text${
            page === 1 && "__bolding-title"
          }`}
        >
          <div className="title-box-1">
            <b>{textTrad[3]}</b>
          </div>
          <br />
          <div className="title-box-2">
            <b>{textTrad[4]}</b>
          </div>
          <br />
          <div className="title-box-3">
            <b>{textTrad[5]}</b>
          </div>
        </div>
        <p
          className={
            page === 1 &&
            "animate__animated animate__fadeInUp animate__delay-1s animate__slow"
          }
        >
          {textTrad[6]}
        </p>
        <p
          className={
            page === 1 &&
            "animate__animated animate__fadeInUp animate__delay-2s animate__slow"
          }
        >
          {textTrad[7]}
        </p>
      </div>
      <img className="h-waterMark" src={MarcaDagua} alt="water mark" />
      <img className="h-logo" src={LogoEquals9} alt="Logo Equals9" />
    </div>
  );
}

export default Home;
