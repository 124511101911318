import React from "react";
import options from "./particlesjs-config.json";
import Particles from "react-particles-js";

function Particlesjs() {
  return (
    <Particles style={{ position: "absolute" }} params={options}></Particles>
  );
}

export default Particlesjs;
