import React, { useEffect, useState } from "react";
import WaterMark from "../../assets/img/MarcaDagua2.png";
import ColorMark from "../../assets/img/Colorida.png";
import { Button, Link } from "@mui/material";
import { useStore } from "../../context/storeContext";
// import language from "../../assets/lang";
import { Link as Link2 } from "react-router-dom";

function Distribution() {
  const { page, lang, language } = useStore();
  const [modal, setModal] = useState(false);
  const text = language[lang].distribution;

  return (
    <div className="page-distribuition">
      <div className="page-distribuition-left">
        <div className="page-distribuition-left-box">
          <img src={WaterMark} alt="water mark" />
          <div
            className={page === 5 && `page-distribuition-left-box-colorMark  `}
          >
            {page === 5 && <img src={ColorMark} alt="color mark" />}
          </div>
        </div>
      </div>
      <div className="page-distribuition-right">
        <h1
          // className={
          //   page === 5 && `animate__animated animate__fadeInUp animate__slow`
          // }
          style={{
            transition:
              page === 5
                ? "margin ease 2s,opacity ease 1s"
                : "margin ease .5s,opacity ease .5s",
            marginLeft: page === 5 ? 0 : "-1rem",
            opacity: page === 5 ? 1 : 0,
          }}
        >
          {text[0]}
        </h1>
        <div
          // className={
          //   page === 5 &&
          //   `animate__animated animate__fadeInUp animate__slow page-distribuition-right-content-text`
          // }
          className="page-distribuition-right-content-text"
          style={{
            transition:
              page === 5
                ? "margin ease 3s,opacity ease 3s"
                : "margin ease .5s,opacity ease .5s",
            marginTop: page === 5 ? 0 : "1rem",
            opacity: page === 5 ? 1 : 0,
          }}
        >
          <p>{text[1]}</p>
          <Link
            className="dist-link"
            href="https://tronscan.org/#/token20/TZ1KcoVfNbB2vHa57rqCoCNHEUp1fikHsH"
            target="blank"
          >
            <Button>{text[2]}</Button>
          </Link>
          <p>{text[3]}</p>
          <div
            // className={
            //   page === 5 &&
            //   `valuesBox animate__animated animate__fadeInUp animate__slow`
            // }
            className="valuesBox"
            style={{
              transition:
                page === 5
                  ? "margin ease 3s,opacity ease 2s"
                  : "margin ease .5s,opacity ease .5s",
              marginTop: page === 5 ? 0 : "1rem",
              opacity: page === 5 ? 1 : 0,
            }}
          >
            <span>
              <p>{text[4]}:</p>
              <b>1.800.000.000</b>
            </span>
            <span>
              <p>{text[5]}:</p>
              <b>450.000.000</b>
            </span>
            <span>
              <p>{text[6]}:</p>
              <b>1.350.000.000</b>
            </span>
          </div>
          <div style={{ position: "relative" }}>
            <Link
              className="dist-link"
              style={{ marginTop: "4%" }}
              // href="https://tronscan.org/#/token20/TZ1KcoVfNbB2vHa57rqCoCNHEUp1fikHsH"
              target="blank"
            >
              <Button
                style={{ width: "210px" }}
                onClick={() => {
                  setModal(!modal);
                }}
              >
                White Paper
              </Button>
            </Link>
            <Modal modal={modal} setModal={setModal} />
          </div>
        </div>
      </div>
    </div>
  );
}

const Modal = ({ modal, setModal }) => {
  return (
    <div
      style={{
        position: "absolute",
        transition: "margin 1s ease,opacity 0.5s ease",
        marginTop: modal ? 0 : "-.6rem",
        opacity: modal ? 1 : 0,
        pointerEvents: modal ? "" : "none",
        width: "100%",
        height: "30px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        marginTop: "2%",
      }}
    >
      <Link2
        className="dist-link"
        style={{ height: "2.5rem", marginRight: "1.5%" }}
        to="/whitepaper-pt"
        target="blank"
        onClick={() => setModal(false)}
      >
        <Button style={{ width: "100px" }}>PT</Button>
      </Link2>
      <Link2
        className="dist-link"
        style={{ height: "2.5rem" }}
        target="blank"
        to="/whitepaper-en"
        onClick={() => setModal(false)}
      >
        <Button style={{ width: "100px" }}>EN</Button>
      </Link2>
    </div>
  );
};

export default Distribution;
